epigraph-ar {
    // Epigraph Custom CSS Properties
    // Cannot consume pattern library colors within epigraph component.
    // ------ Epigraph Loading Ring ------
    --progress-ring-color: #6b6257; // color(darkContrast, 2)

    // ------- Epigraph Viewer Close Button --------
    --product-tour-close-button-fill-color: #fff;
    --product-tour-close-button-stroke-color: #6b6257; // color(darkContrast, 2)

    // ------- Epigraph QR Modal --------
    --qr-modal-close-button-stroke-color: #000;
    --qr-modal-done-button-background-color: #d5aa63;
    --qr-modal-done-button-hover-background-color: #e2c492;
    --qr-modal-done-button-active-background-color: #b19780;
    --qr-modal-done-button-border-color: transparent;
    --qr-modal-done-button-text-color: #fff;

    &::part(ar-button-container) {
        display: none;
    }

    &::part(qr-modal-title-default) {
        font-size: set-fontSize(24);
        font-family: "Source Serif Pro";
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        text-align: center;
        color: color(text, base);
    }

    &::part(qr-modal-instruction-default) {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        font-size: set-fontSize(18);
        line-height: spacer(lg);
        color: color(text, base);
        text-align: left;
    }

    &::part(qr-modal-done-default) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: spacer(xs) spacer(lg);
        gap: spacer(xs);
        width: 200px;
        height: spacer(2dot5xl);
        border-radius: spacer(3xs);
    }

    &::part(qr-modal-close-default) {
        opacity: 1;
        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .vrReplacementPoster {
        width: 100%;
        height: 100%;

        &--loadingIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__svg {
            height: 110px;
            width: 110px;
        }
    }
}

.arButtonContainer {
    epigraph-ar {
        background-color: transparent;

        &::part(ar-button-container) {
            display: flex;
            align-items: center;
        }
    }

    &__arButton {
        display: flex;
        align-items: center;
        padding: spacer(2xs) spacer(xs);
        border-radius: 4px;
        border: solid 1px color(darkContrast, 2);
        background-color: color(contrast, white);
        color: color(darkContrast, 2);
        width: max-content;
        cursor: pointer;

        &__inner {
            display: flex;
            align-items: center;
            gap: spacer(xs);
            font-size: set-fontSize(12);

            .arButtonContainer__svg {
                height: 18px;
                width: 18px;
            }
        }

        &:hover {
            color: color(contrast, white);
            background-color: color(darkContrast, 2);

            [data-style-svgtype] {
                color: color(contrast, white);
            }
        }

        &:active {
            color: color(contrast, white);
            background-color: color(darkContrast, 3);
        }
    }

    &__tooltip {
        margin-left: spacer(2xs);

        @include breakpoint(md) {
            margin-left: spacer(base);
        }

        &--button {
            bottom: calc(#{spacer(2xs)} + #{3px});
        }

        &--content {
            font-size: set-fontSize(14);
            padding: spacer(sm);
            right: -10px;
        }
    }

    .arDisclaimer {
        font-weight: $fontWeight-light;
        color: color(text, base); // was #757575, now matches the rest of the tooltip
        width: 100%;

        &__buystack {
            font-size: set-fontSize(12);
            line-height: spacer(base);
        }
    }

    .qrModalRequirement {
        font-family: sans-serif;
        font-size: set-fontSize(16);
        color: #3f82f9;
        display: none;
        line-height: 18px;
        text-align: left;

        &__techList {
            list-style: disc;
            margin-left: spacer(2xl);
        }

        &.show {
            display: block;
        }
    }
}

.arDisclaimer {
    font-weight: $fontWeight-light;
    color: color(text, base); // was #757575, now matches the rest of the tooltip
    text-align: left;
    width: 100%;

    &__carousel {
        position: absolute;
        bottom: spacer(xs);
        left: spacer(base);
        font-size: set-fontSize(14);
        text-align: center;
        color: #757575; // within the carousel
    }

    &__buystack {
        font-size: set-fontSize(12);
        line-height: spacer(base);
    }
}
