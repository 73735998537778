.discountBadge {
    display: inline-block;
    padding: spacer(3xs) spacer(2xs);

    font-size: set-fontSize(12);
    font-weight: 400;
    color: color(contrast, white);
    line-height: 1;

    background-color: color(cta, base);
}
