@mixin tabCarouselSlider($sku, $slideCount) {
    @for $i from 1 through $slideCount {
        &--image--#{$i - 1} {
            @include responsive-dpi-img($sku, "image-accordion-slider-#{$i - 1}", "jpg", false, true, false);
            background-size: cover;
        }
    }
}

.tabCarousel {
    overflow: hidden;

    &__slideFooterTitle {
        color: color(text, base);
    }

    &__tabListContainer {
        border-bottom: solid 1px color(contrast, 4);
        margin-bottom: spacer(md);

        @include breakpoint(lg) {
            overflow-x: auto;
        }
    }

    &__tabListScroll {
        margin-left: calc((46vw / 2));
        transition: all 0.37s cubic-bezier(0, 0, 0.2, 1);

        @include breakpoint(sm) {
            margin-left: calc(#{map-get($container-widths, sm)}/ 2);
        }

        @include breakpoint(md) {
            margin-left: calc(#{map-get($container-widths, md)}/ 2);
        }

        @include breakpoint(lg) {
            margin-left: 0;
            transform: translateX(0) !important; // TODO: determine best handling of no scrolling in desktop (not this)
        }
    }

    &__tabList {
        justify-content: space-around;
        margin: spacer(2dot5xl) 0 0;
        display: flex;

        &:after {
            content: "";
            height: 2px;
            background-color: color(text, recede);
        }
    }

    &__tabName {
        color: color(text, recede);
        filter: grayscale(100%);
        flex: 1 0 auto;
        font-size: set-fontSize(14);
        font-weight: normal;
        letter-spacing: 3px;
        margin: 0 1rem;
        opacity: 0.8;
        padding-bottom: spacer(md);
        text-transform: uppercase;
        transition: all 250ms ease-in-out;
        max-width: 95%;

        &[aria-selected="true"] {
            color: color(text, base);
            filter: grayscale(0);
            opacity: 1;
        }

        @include breakpoint(sm) {
            margin: 0 2rem;
        }

        @include breakpoint(lg) {
            flex: none;
            margin: 0 spacer(sm);
        }

        &:focus {
            outline: none;
        }
    }

    &__tabSlider {
        background-color: color(text, base);
        bottom: -2px;
        height: 3px;
    }

    &__images {
        &--solaire {
            @include tabCarouselSlider("solaire", 5);
        }
    }

    &__slider {
        height: 680px;
        margin: 0 auto;
        max-width: 375px;

        &::after {
            content: "flickity";

            display: none;

            @include breakpoint(lg) {
                content: "";
            }
        }

        .flickity-prev-next-button.previous {
            left: 0;
        }

        .flickity-prev-next-button.next {
            right: 0;
        }

        .flickity-page-dots {
            justify-content: center;
            margin-top: spacer(5xl);
        }

        &.marginBottomDots {
            .flickity-page-dots {
                margin-bottom: -(spacer(5xl));
            }
        }
    }

    &__slide {
        color: color(darkContrast, 3);
        height: 680px;
        width: 100%;

        @include set-font(24, normal);

        &__picture {
            width: 100%;
        }

        &Footer {
            padding: 0 spacer(base);

            &Content {
                list-style: disc outside;
                padding: 0 0 0 spacer(base);
                width: 100%;
                b {
                    font-weight: 400;
                }
            }
        }
    }

    &__picture {
        height: 300px;
        object-fit: cover;
        width: 100%;
    }

    &__dots {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 2rem 0 0;
        line-height: 1;

        ol,
        ul {
            list-style: none;
        }

        .dot {
            background: color(contrast, 3);
            border: color(contrast, 3);
            border-radius: 0;
            margin: 0;
            height: 1px;
            width: 20%;

            &.is-selected {
                background: color(darkContrast, 3);
                transition: all 350ms ease-in;
            }
        }
    }

    &__slideFooter {
        text-align: left;
    }
}
