.tabbedContentDisplay {
    display: flex;
    flex-direction: column;

    &__tabList {
        background-color: color(contrast, white);
        border: solid 1px color(contrast, 3);
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: spacer(3xs);

        @include breakpoint(lg) {
            width: 442px;
        }
    }

    &__tabName {
        background-color: color(contrast, white);
        font-size: set-fontSize(16);
        padding: spacer(2xs);
        width: 100%;

        @include breakpoint(lg) {
            margin: 0 spacer(3xs);
        }

        &:hover {
            background-color: color(accent, greenLight);
            border-radius: 4px;
        }

        &--isActive {
            background-color: color(accent, green);
            border-radius: 4px;
            color: color(state, base);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
            
            &:hover{
                background-color: color(accent, green);
                border-radius: 4px;
            }
        }

        &--blueColor{
            background-color: color(accent, white);

            &:hover{
                background-color: color(contrast, 1);
                border-radius: 4px;
            }

            &--isActive {
                background-color: color(accent, blue);
                border-radius: 4px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
                
                &:hover{
                    background-color: color(accent, blue);
                    color: color(text, white);
                }
            }
        }

    }

    &__tabContentWrapper {
        display: none;

        @include breakpoint(lg) {
            min-height: 413px;
        }

        &.is-active {
            display: inline-block;
        }
    }

    &__tabPanel {
        background-color: color(accent, greenLight);
        padding: spacer(base)
    }

    &__tabHeading {
        font-size: set-fontSize(18);
        font-weight: normal;
        line-height: 1.44;
        margin-bottom: spacer(base);
    }

    &__tabContent {
        font-size: set-fontSize(16);
        line-height: 1.5;

        ul {
            list-style: disc;
            margin-left: spacer(base);
            margin-bottom: spacer(base);
        }

        li {
            margin-bottom: spacer(xs);
        }
    }

    &__iconWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__svg {
        max-width: 18%;
    }

    &__link {
        color: color(accent, blue);
        text-decoration: underline;
        font-weight: 400;
        cursor: pointer;
    }
}
