.includedAddonList {
    &__container {
        background-color: color(contrast, 5);
        margin-bottom: spacer(sm);
        padding: spacer(xs) spacer(sm);
        border-top: none;
        border: none;
        border-left: 1px solid #000;
        text-align: left;
    }

    &__title {
        font-size: set-fontSize(16);
        font-weight: 400;
    }

    &__check {
        &:before {
            content: "";
            width: 11px;
            height: 6px;
            border-left: 2px solid color(action, dark);
            border-bottom: 2px solid color(action, dark);
            transform: scale(1) rotate(-45deg);
            position: absolute;
            left: calc((1rem - 11px) / 2);
            top: calc((1rem - 3px) / 2);
        }
    }

    &__selectionCopy {
        color: color(text, base);
        font-size: set-fontSize(14);
        font-weight: 300;
        padding-left: calc(1.5rem);
    }

    &__added {
        font-style: italic;
        font-weight: 600;
        font-size: set-fontSize(16);
        color: #c1a38b;
        @include breakpoint(sm) {
            margin-left: spacer(xs);
        }
    }
}
