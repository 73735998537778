.foundationDropdownAddon {  
    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;
        font-size: set-fontSize(14);
        line-height: spacer(lg);
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__modalToggle {
        font-size: set-fontSize(14);
        font-weight: normal;
        line-height: spacer(md);

        &--rightAligned {
            margin: auto 0 0 auto;
        }
    }

    &__dropdown {
        text-align: left;
        
        &--sizeContainer {
            flex: 1 0 auto;
        }

        &--size {
            height: 22rem;
        }

        .dropdown__text {
            width: 100%;
            text-align: left;
        }

        &--text {
            width: 100%;
            text-align: left;
        }
    }

    &__dropdownWrapper {
        width: 100%;
        z-index: z(dropdown);
    }

    &__belowDropdownDescription {
        background-color: color(contrast, 5);
        margin-bottom: spacer(sm);
        padding: spacer(xs) spacer(sm) spacer(base);
        border: 1px solid color(contrast, 3);
        border-top: none;
        text-align: left;

        &--list {
            list-style-type: disc;
            padding-left: spacer(md);
        }
    }

    &__onSale {
        color: color(negative, base);
    }
}
