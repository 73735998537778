@use "sass:math";

.youthFoundationDiagram {
    margin-top: -3rem;

    @include breakpoint(md) {
        margin-top: 5rem;

    }

    @include breakpoint(md) {
        width: 100%;
        padding-top: calc(100% * (312/462));
        background-size: contain;
        background-repeat: no-repeat;

        background-image: url('#{$rootAssetImagePath}/images/bases/saatva-youth-foundation-height/d-saatva-youth-foundation-height@1x.png');

        @media screen and (min-resolution: 192dpi) {
            background-image: url('#{$rootAssetImagePath}/images/bases/saatva-youth-foundation-height/d-saatva-youth-foundation-height@2x.png');
        }
    }

    &__note {
        font-size: set-fontSize(14);
        font-style: italic;
    }

    &__intro {

        @include breakpoint(md) {
            top: -7rem;
        }

        margin-bottom: spacer(xl);
        top: -4.5rem;
        width: 100%;

        @include breakpoint(md) {
            width: 70%;
            margin-left: 3.5rem;
            position: absolute;
            left: 140px;
            margin-bottom: 0;
        }
    }

    &__content {
        @include breakpoint(md) {
            position: absolute;
            top: 0;
            left: 0;
            padding-top: calc((100%) * (78/584));
        }
    }

    &__singleImg {
        top: 0;

        @include breakpoint(md) {
            top: 15rem;
        }

        padding-top: calc((100% - #{$grid-gutter}) * (334/323));
        background-size: contain;
        background-repeat: no-repeat;
        padding-bottom: 1rem;

        @include breakpoint(md) {
            padding: 0;
        }

        &--475 {
            @include responsive-dpi-img('bases', 'saatva-youth-foundation-height-475', png, 'false');
            margin-bottom: spacer(2dot5xl);

            @include breakpoint(md) {
                margin-bottom: 0;
                background-image: none;
            }
        }

        &--875 {
            @include responsive-dpi-img('bases', 'saatva-youth-foundation-height-875', png, 'false');

            @include breakpoint(md) {
                background-image: none;
            }
        }

    }

    &__text {
        $_hdFoundation-textOffsetCalc: 100% * math.div(150, 335);
        $_hdFoundation-textWidth: 182px;
        padding: 0;
        position: absolute;
        top: 1rem;
        left: 0;
        height: 100%;

        padding-left: calc(#{$_hdFoundation-textOffsetCalc});
        max-width: calc(#{$_hdFoundation-textOffsetCalc} + 182px);

        @include breakpoint(md) {
            position: relative;
            top: auto;
            left: auto;
            max-width: 100%;
            padding: 0;
        }

        &--475 {
            @include breakpoint(md) {
                padding: 0 spacer(base) 0 0;
            }
        }

        &--875 {
            @include breakpoint(md) {
                padding: 0 spacer(base) 0;
            }
        }
    }

    @include breakpoint(sm) {
        display: flex;
        flex-direction: column-reverse;
    }

    @include breakpoint(md) {
        display: flex;
        flex-direction: row;
    }
}

.hdFoundationDiagram {
    margin-top: -5rem;

    @include breakpoint(md) {
        margin-top: 5rem;
    }

    @include breakpoint(md) {
        width: 100%;
        padding-top: calc(100% * (323/584));
        background-size: contain;
        background-repeat: no-repeat;

        background-image: url('#{$rootAssetImagePath}/images/bases/saatva-hd-foundation-height/d-saatva-hd-foundation-height@1x.png');

        @media screen and (min-resolution: 192dpi) {
            background-image: url('#{$rootAssetImagePath}/images/bases/saatva-hd-foundation-height/d-saatva-hd-foundation-height@2x.png');
        }
    }

    &__note {
        font-size: set-fontSize(14);
        font-style: italic;
    }

    &__content {
        @include breakpoint(md) {
            position: absolute;
            top: 0;
            left: 0;
            padding-top: calc((100%) * (78/584));
        }
    }

    &__intro {
        @include breakpoint(md) {
            top: -7rem;
        }

        margin-bottom: spacer(xl);
        top: -4.5rem;
        width: 100%;

        @include breakpoint(md) {
            width: 70%;
            margin-left: 1rem;
            position: absolute;
            left: 140px;
            margin-bottom: 0;
        }
    }

    &__singleImg {
        @include breakpoint(sm) {
            top: 17rem;
        }

        padding-top: calc((100% - #{$grid-gutter}) * (334/323));
        background-size: contain;
        background-repeat: no-repeat;
        padding-bottom: 1rem;

        @include breakpoint(md) {
            padding: 0;
        }

        &--475 {
            @include responsive-dpi-img('bases', 'saatva-hd-foundation-height-475', png, 'false');
            margin-bottom: spacer(2dot5xl);

            @include breakpoint(md) {
                margin-bottom: 0;
                background-image: none;
            }
        }

        &--875 {
            @include responsive-dpi-img('bases', 'saatva-hd-foundation-height-875', png, 'false');

            @include breakpoint(md) {
                background-image: none;
            }
        }

    }

    &__text {
        $_hdFoundation-textOffsetCalc: 100% * math.div(127, 335);
        $_hdFoundation-textWidth: 182px;
        padding: 0;
        position: absolute;
        top: 1rem;
        left: 0;
        height: 100%;

        padding-left: calc(#{$_hdFoundation-textOffsetCalc});
        max-width: calc(#{$_hdFoundation-textOffsetCalc} + 182px);

        @include breakpoint(md) {
            position: relative;
            top: auto;
            left: auto;
            max-width: 100%;
            padding: 0;
        }

        &--475 {
            @include breakpoint(md) {
                padding: 0 spacer(base) 0 0;
            }
        }

        &--875 {
            @include breakpoint(md) {
                padding: 0 spacer(base) 0;
            }
        }
    }

    @include breakpoint(sm) {
        display: flex;
        flex-direction: column-reverse;
    }

    @include breakpoint(md) {
        display: flex;
        flex-direction: row;
    }
}

.smMattressFoundationDiagram {
    &__intro {
        margin-bottom: spacer(xl);

        @include breakpoint(md) {
            position: absolute;
            top: -40px;
            left: 140px;

            margin-bottom: 0;
        }
    }

    &__header {
        margin-bottom: spacer(sm);
        text-align: left;
    }

    &__note {
        font-size: set-fontSize(14);
        font-style: italic;
    }

    &Diagram {
        @include breakpoint(md) {
            width: 100%;
            padding-top: calc((100% * (304 / 604)) + 45px);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left bottom;
        }

        &--mattress {
            @include breakpoint(md) {
                background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-mattress-height/d-saatva-classic-mattress-height@1x.png");
                padding-top: calc((100% * (304 / 604)) + 70px);

                @media screen and (min-resolution: 192dpi) {
                    background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-mattress-height/d-saatva-classic-mattress-height@2x.png");
                }
            }
        }

        &--foundation {
            @include breakpoint(md) {
                background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-foundation-height/d-saatva-classic-foundation-height@1x.png");

                @media screen and (min-resolution: 192dpi) {
                    background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-foundation-height/d-saatva-classic-foundation-height@2x.png");
                }
            }
        }

        &__content {
            @include breakpoint(md) {
                display: none;
            }
        }

        &__singleImg {
            padding-top: calc((100% - #{$grid-gutter}) * (304 / 335));
            background-size: contain;
            background-repeat: no-repeat;

            @include breakpoint(md) {
                padding: 0;
            }

            &--145 {
                @include responsive-dpi-img("bases", "saatva-classic-mattress-height-14", png, "false");
                margin-bottom: spacer(xl);

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--875 {
                @include responsive-dpi-img("bases", "saatva-classic-foundation-height-875", png, "false");
                margin-bottom: spacer(xl);

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--115 {
                @include responsive-dpi-img("bases", "saatva-classic-mattress-height-11", png, "false");

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--475 {
                @include responsive-dpi-img("bases", "saatva-classic-foundation-height-475", png, "false");

                @include breakpoint(md) {
                    background-image: none;
                }
            }
        }
    }
}

.smMattressFoundation {
    &__intro {
        margin-bottom: spacer(xl);

        @include breakpoint(md) {
            position: absolute;
            top: -40px;
            left: 140px;

            margin-bottom: 0;
        }
    }

    &__header {
        margin-bottom: spacer(sm);
        text-align: left;
    }

    &__note {
        font-size: set-fontSize(14);
        font-style: italic;
    }

    &Diagram {
        @include breakpoint(md) {
            width: 100%;
            padding-top: calc((100% * (304 / 604)) + 45px);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left bottom;
        }

        &--mattress {
            @include breakpoint(md) {
                background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-mattress-height/d-saatva-classic-mattress-height@1x.png");
                padding-top: calc((100% * (304 / 604)) + 70px);

                @media screen and (min-resolution: 192dpi) {
                    background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-mattress-height/d-saatva-classic-mattress-height@2x.png");
                }
            }
        }

        &--foundation {
            @include breakpoint(md) {
                background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-foundation-height/d-saatva-classic-foundation-height@1x.png");

                @media screen and (min-resolution: 192dpi) {
                    background-image: url("#{$rootAssetImagePath}/images/bases/saatva-classic-foundation-height/d-saatva-classic-foundation-height@2x.png");
                }
            }
        }

        &__content {
            @include breakpoint(md) {
                display: none;
            }
        }

        &__singleImg {
            padding-top: calc((100% - #{$grid-gutter}) * (304 / 335));
            background-size: contain;
            background-repeat: no-repeat;

            @include breakpoint(md) {
                padding: 0;
            }

            &--145 {
                @include responsive-dpi-img("bases", "saatva-classic-mattress-height-14", png, "false");
                margin-bottom: spacer(xl);

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--875 {
                @include responsive-dpi-img("bases", "saatva-classic-foundation-height-875", png, "false");
                margin-bottom: spacer(xl);

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--115 {
                @include responsive-dpi-img("bases", "saatva-classic-mattress-height-11", png, "false");

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--475 {
                @include responsive-dpi-img("bases", "saatva-classic-foundation-height-475", png, "false");

                @include breakpoint(md) {
                    background-image: none;
                }
            }
        }
    }
}