// generate the third image in the slider, which is unique to each PDP
@mixin featuresSliderProductImage($productCode) {
    @include responsive-dpi-imgix($folder: '/products/#{$productCode}/designer-details',
        $name: '#{$productCode}-designer-details-4-3',
        $mobileWidth: 800,
        $tabletWidth: null,
        $desktopWidth: null,
        $isUniversal: false,
        $usePrefix: false
    );
}

.featuresSlider {
    margin: spacer(base) auto 0;
    overflow: hidden;
    width: 100%;

    &__slides {
        display: table;
        margin: 0;
        padding: 0;
        table-layout: fixed;
        width: 100%;
    }

    &__slideImage {
        background-position: center center;
        background-repeat: no-repeat;
        border: 5px solid color(contrast, white);
        display: table-cell;
        height: 370px;
        position: relative;
        transition: all 500ms ease;
        vertical-align: bottom;
        width: 10%;

        @include breakpoint(lg) {
            height: 600px;
        }

        &:hover {
            width: 60%;
        }

        &.is-active {
            width: 60%;
        }
    }

    &__tabListScroll {
        margin-bottom: spacer(lg);
        width: 100%;

        @include breakpoint(lg) {
            margin-bottom: spacer(3xl);
        }
    }

    &__tabList {
        display: flex;
        border-bottom: solid 2px color(contrast, 3);
        justify-content: space-between;
        padding-bottom: spacer(md);

        @include breakpoint(lg) {
            margin-top: spacer(2xl);
        }
    }

    &__tabName {
        color: color(text, recede);
        font-size: set-fontSize(14);
        font-weight: normal;
        letter-spacing: 3px;
        padding: 0 spacer(sm);
        text-transform: uppercase;

        @include breakpoint(lg) {
            padding: 0;
        }

        &[aria-selected="true"] {
            color: color(text, base);
        }

        &:focus {
            outline: none;
        }

        @include breakpoint(lg) {
            font-size: set-fontSize(16);
            letter-spacing: 4px;
        }
    }

    &__tabSlider {
        background-color: color(text, base);
        bottom: -2px;
        height: 3px;
    }

    &__tabHeading {
        color: color(text, base);
        font-size: set-fontSize(28);
        font-weight: normal;
        line-height: 1;
        margin: spacer(md) 0 spacer(sm);

        @include breakpoint(lg) {
            font-size: set-fontSize(32);
            margin: spacer(2dot5xl) 0 spacer(md);
        }
    }

    &__footer {
        font-family: $fontFamily-sans-serif;
        margin-left: spacer(2xs);
        text-align: left;
        transition: all 350ms ease-in;

        &Title {
            font-size: set-fontSize(20);
            font-weight: 400;
        }

        &List {
            list-style: disc outside;
            padding: 0 0 0 spacer(base);

            b {
                font-weight: 400;
            }
        }

        &Description {
            font-size: set-fontSize(16);
            font-weight: 300;
            line-height: 2;
        }
    }

    &__slideImage {
        background-size: cover;

        // The first image is the same on all PDPs
        &--trees {
            @include responsive-dpi-imgix($folder: '/generic/trees',
                $name: 'trees-4-3',
                $mobileWidth: 800,
                $tabletWidth: null,
                $desktopWidth: null,
                $isUniversal: false,
                $usePrefix: false
            );
        }

        // the second image is one of two images, depending on if the bed frame is leather or fabric
        &--closer-look-fabric {
            @include responsive-dpi-imgix($folder: '/generic/closer-look-fabric',
                $name: 'closer-look-fabric-4-3',
                $mobileWidth: 800,
                $tabletWidth: null,
                $desktopWidth: null,
                $isUniversal: false,
                $usePrefix: false
            );
        }

        &--closer-look-leather {
            @include responsive-dpi-imgix($folder: '/generic/closer-look-leather',
                $name: 'closer-look-leather-4-3',
                $mobileWidth: 800,
                $tabletWidth: null,
                $desktopWidth: null,
                $isUniversal: false,
                $usePrefix: false
            );
        }

        &--closer-look-oak-veneers {
            @include responsive-dpi-imgix($folder: '/generic/closer-look-oak-veneers',
                $name: 'closer-look-oak-veneers-4-3',
                $mobileWidth: 800,
                $tabletWidth: null,
                $desktopWidth: null,
                $isUniversal: false,
                $usePrefix: false
            );
        }

        // the third image is unique to each PDP
        &--rhodes {
            @include featuresSliderProductImage('rhodes')
        }

        &--santorini {
            @include featuresSliderProductImage('santorini')
        }

        &--santorini-platform-bed {
            @include featuresSliderProductImage('santorini-platform-bed')
        }

        &--halle-with-storage {
            @include featuresSliderProductImage('halle-with-storage')
        }

        &--valencia {
            @include featuresSliderProductImage('valencia')
        }

        &--sydney {
            @include featuresSliderProductImage('sydney')
        }

        &--siena-leather {
            @include featuresSliderProductImage('siena-leather')
        }

        &--porto {
            @include featuresSliderProductImage('porto')
        }

        &--minori {
            @include featuresSliderProductImage('minori')
        }

        &--marseille {
            @include featuresSliderProductImage('marseille')
        }

        &--marbella {
            @include featuresSliderProductImage('marbella')
        }

        &--lucerne {
            @include featuresSliderProductImage('lucerne')
        }

        &--halle {
            @include featuresSliderProductImage('halle')
        }

        &--copenhagen {
            @include featuresSliderProductImage('copenhagen')
        }

        &--amalfi {
            @include featuresSliderProductImage('amalfi')
        }

        &--amalfi-platform-bed {
            @include featuresSliderProductImage('amalfi-platform-bed')
        }

        &--amalfi-with-storage {
            @include featuresSliderProductImage('amalfi-with-storage')
        }

        &--halle-storage-platform-bed {
            @include featuresSliderProductImage('halle-storage-platform-bed')
        }

        &--cassis {
            @include featuresSliderProductImage('cassis')
        }

        &--cassis-with-storage {
            @include featuresSliderProductImage('cassis-with-storage')
        }

        &--calais {
            @include featuresSliderProductImage('calais')
        }

        &--lyon {
            @include featuresSliderProductImage('lyon')
        }

        &--milan {
            @include featuresSliderProductImage('milan')
        }

        &--merano {
            @include featuresSliderProductImage('merano')
        }

        &--crete {
            @include featuresSliderProductImage('crete')
        }

        &--adra-leather {
            @include featuresSliderProductImage('adra-leather')
        }

        &--cologne-wood {
            @include featuresSliderProductImage('cologne-wood')
        }

        &--corsica-wood {
            @include featuresSliderProductImage('corsica-wood')
        }

        &--matera-wood {
            @include featuresSliderProductImage('matera-wood')
        }

        &--lyon-with-storage {
            @include featuresSliderProductImage('lyon-with-storage')
        }

        &--minori-with-storage {
            @include featuresSliderProductImage('minori-with-storage')
        }
    }
}