// TODO: evaluate these styles to be cleaned up and moved to pattern library

$imgixUrls: (
    'local': 'https://saatva-qa.imgix.net',
    'dev': 'https://saatva-qa.imgix.net',
    'qa': 'https://saatva-qa.imgix.net',
    'stage': 'https://saatva-stage.imgix.net',
    'production': 'https://saatva.imgix.net'
);

// Image/Asset variables
$rootAssetImagePath: '/static/assets';

$imgixDomain: map-get($imgixUrls, $env);

@mixin responsive-dpi-imgix(
    $folder,
    $name,
    $mobileWidth,
    $tabletWidth: null,
    $desktopWidth: null,
    $isUniversal: false,
    $usePrefix: true
) {
    $mobilePrefix: if($usePrefix, if($isUniversal, 'u-', 'm-'), '');
    $tabletPrefix: if($usePrefix, if($isUniversal, 'u-', 't-'), '');
    $desktopPrefix: if($usePrefix, if($isUniversal, 'u-', 'd-'), '');

    background-image: url('#{$imgixDomain}#{$folder}/#{$mobilePrefix}#{$name}.jpg?dpr=1&auto=format&w=#{$mobileWidth}');

    @include breakpoint(md) {
        @if $tabletWidth {
            background-image: url('#{$imgixDomain}#{$folder}/#{$tabletPrefix}#{$name}.jpg?dpr=1&auto=format&w=#{$tabletWidth}');
        }
    }

    @include breakpoint(lg) {
        @if $desktopWidth {
            background-image: url('#{$imgixDomain}#{$folder}/#{$desktopPrefix}#{$name}.jpg?dpr=1&auto=format&w=#{$desktopWidth}');
        }
    }

    @media screen and (min-resolution: 192dpi) {
        background-image: url('#{$imgixDomain}#{$folder}/#{$mobilePrefix}#{$name}.jpg?dpr=2&auto=format&w=#{$mobileWidth}');

        @include breakpoint(md) {
            @if $tabletWidth {
                background-image: url('#{$imgixDomain}#{$folder}/#{$tabletPrefix}#{$name}.jpg?dpr=2&auto=format&w=#{$tabletWidth}');
            }
        }

        @include breakpoint(lg) {
            @if $desktopWidth {
                background-image: url('#{$imgixDomain}#{$folder}/#{$desktopPrefix}#{$name}.jpg?dpr=2&auto=format&w=#{$desktopWidth}');
            }
        }
    }
}


@function colorHexOpacity($color, $opacityNum) {
    @return #{$color}#{$opacityNum};
}

// TODO: Figure out why nextJS is not picking up this mixin coming from the pattern library
@mixin use-webp-if-available($filepath, $extension, $hasWebpFile) {
    background-image: url('#{$filepath}.#{$extension}');
    @if ($hasWebpFile == true) {
        .webp & {
            background-image: url('#{$filepath}.webp');
        }
    }
}

@mixin conditional-compound($condition, $selector) {
    @if $condition{
        &#{$selector}{
            @content;
        }
    } @else {
        @content;
    }
}

@function getImageSource($folder, $name, $devicePrefix, $density) {
    $devicePrefixString: '#{$devicePrefix}-';
    @return '#{$rootAssetImagePath}/images/#{$folder}/#{$name}/#{$devicePrefixString}#{$name}@#{$density}x';
}

@mixin responsive-dpi-img($folder, $name, $type:jpg, $excludeTablet:false, $hasWebpFiles:false, $isProgressive:false) {
    @if ($isProgressive) {
        background-image: url('#{$rootAssetImagePath}/images/#{$folder}/#{$name}/p-#{$name}.jpg');
    }

    @include conditional-compound($isProgressive, '.is-loaded') {
        @if ($isProgressive) {
            animation-name: blurIn;
            animation-duration: .5s;
            @keyframes blurIn {
                0% {
                    filter: blur(10px);
                }
                100% {
                    filter: blur(0);
                }
            }
        }

        @include use-webp-if-available(getImageSource($folder, $name, 'm', '1'), $type, $hasWebpFiles);

        @if ($excludeTablet == false) {
            @include breakpoint(md) {
                @include use-webp-if-available(getImageSource($folder, $name, 't', '1'), $type, $hasWebpFiles);
            }
        }

        @include breakpoint(lg) {
            @include use-webp-if-available(getImageSource($folder, $name, 'd', '1'), $type, $hasWebpFiles);
        }

        @media screen and (min-resolution: 192dpi) {
            @include use-webp-if-available(getImageSource($folder, $name, 'm', '2'), $type, $hasWebpFiles);

            @if ($excludeTablet == false) {
                @include breakpoint(md) {
                    @include use-webp-if-available(getImageSource($folder, $name, 't', '2'), $type, $hasWebpFiles);
                }
            }

            @include breakpoint(lg) {
                @include use-webp-if-available(getImageSource($folder, $name, 'd', '2'), $type, $hasWebpFiles);
            }
        }
    }
}
