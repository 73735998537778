//-------------------------------------------
// TabList Styles
// ------------------------------------------

// TabList Variables

.tabList {
    display: flex;
    justify-content: space-around;
    width: 100%;

    &__tab {
        cursor: pointer;
    }

    &__slider {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 4px;
        background-color: color(nav, active);
        transition: all 500ms ease;
    }
}

// Defined Tab Styles
.standardTab {
    @include hover-focus-active {
        color: color(nav, active);
    }

}
.numberTab {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    
    @include hover-focus-active {
        color: color(nav, active);

        .numberTab__number {
            background-color: color(nav, active);
        }
    }

    &__number {
        display: inline-block;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        margin-bottom: spacer(2xs);
        border-radius: 50%;
        background-color: color(nav, inactive);
        color: color(contrast, white);
        @include set-font(16, 20);
            
    }
}


.is-active {
    .standardTab {
        color: color(nav, active);
    }

    .numberTab {
        color: color(nav, active);

        &__number {
            background-color: color(nav, active);
        }
    }
}

$tab-typography: (
    'standardTab': (
        size: 18,
        lineHeight: 1.33,
        weight: $fontWeight-base,
        marginBottom: spacer(xs),
        color: color(nav, inactive)
    ),
    'numberTab': (
        size: 16,
        lineHeight: 1.75,
        weight: $fontWeight-base,
        marginBottom: spacer(xs),
        color: color(nav, inactive)
    )
);

@include makeTypoClasses($tab-typography, '.');
