.productSelectToggle {
    display: grid;
    grid-template-columns: 1fr;
    gap: spacer(xs);

    @include breakpoint(md) {
        grid-template-columns: repeat(2, 1fr);
        margin-left: spacer(2xl);
    }
}

.productSelectToggle__group {
    display: flex;
    gap: spacer(xs);
}
