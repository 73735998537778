$productPanel-quantity-width: 62px;

.productPanel {
    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;

        &.has-quantity {
            flex-basis: calc(100% - #{$productPanel-quantity-width} - #{spacer(base)});
            margin-right: spacer(base);
        }

        &--quantity {
            flex-basis: $productPanel-quantity-width;
        }

        &.isRecommendedProductModal {
            font-size: 16px;
            color: #000;
        }
    }

    &__modalToggle {
        &--rightAligned {
            margin: auto 0 0 auto;
        }
    }

    &__onSale {
        color: color(negative, base);
    }
}
