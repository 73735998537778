.priceSnapshot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__wrapper {
        display: flex;
        flex-direction: column;

        @include breakpoint(md) {
            flex-direction: row;
        }
    }

    &__container {
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        @include set-font(16, 20);

        @include breakpoint(md) {
            justify-content: flex-start;
        }

        // if we have two products in the snapshot, we want to spread them out vertically in mobile
        &:not(:first-child) {
            @include breakpoint(sm, max) {
                line-height: 2.5;
            }
        }
    }

    &__prices {
        font-weight: 300;
        line-height: 20px; // has to match discount size
        display: flex;
    }

    &__discount {
        font-size: set-fontSize(20px);
        font-weight: 400;
        margin-right: spacer(2xs);
    }

    &__noDiscount {
        font-weight: 400;
        line-height: 20px;
        font-size: set-fontSize(20px);
    }

    &__pricePrefix {
        margin-right: spacer(2xs);
    }

    &__klarna {
        @include set-font(16, 1.25);
        margin-top: spacer(2xs);
    }

    &__affirmMessaging {
        
        span.affirm-payment {
            font-weight: 400;
          }
    }
}
