.fancyBanner {
    &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -2px;
        width: 21px;
        border: spacer(xs) solid color(accent, base);
        border-width: 11px spacer(2xs);
        z-index: 0;
    }

    &:before {
        left: -13px;
        border-left-color: transparent;
    }
    &:after {
        right: -13px;
        border-right-color: transparent;
    }
    &__content{
        font-weight: normal;
        background-color: color(accent, recede);
        font-size: set-fontSize(15);
        z-index: 2;
        height: 100%;
        min-height: 22px;
        padding: 0 spacer(xs) spacer(2xs);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
        text-align: center;
        @include between-breakpoint(lg, lg){
            font-size: set-fontSize(12);
            padding: 0 spacer(xs) spacer(3xs);
        }
        &:before, &:after {
            content: "";
            position: absolute;
            display: block;
            border-style: solid;
            border-color: color(accent, text) color(accent, text) color(accent, text) transparent;
            bottom: -2px;
        }
        &:before {
            left: 0;
            border-width: spacer(3xs) 0 0 spacer(xs);
        }
        &:after {
            right: 0;
            border-width: spacer(3xs) 0 0 spacer(xs);
            transform: scaleX(-1);
        }
    }
    &__wrapper {
        display: flex;
        justify-content: center;
    }
}
