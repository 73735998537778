.productTileSlider > .container {
    @include breakpoint(md) {
        max-width: 100%;
    }

    @include breakpoint(lg) {
        max-width: 932px;
    }

    @include breakpoint(xl) {
        max-width: 1110px;
    }
}