// ============ variables ============
$tagWidth: 174px;
$tagHeight: 26px;
$tagBackgroundColor: #c1a38b;
$checkboxColor: #c19678;
// ============ variables ============

.addonTile {
    &Container {
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
        border-radius: spacer(2xs);
        padding: spacer(2xl);
        // width: 328px; // moved to media query for ~320px wide screens
        @media screen and (min-width: 360px) {
            width: 328px;
        }
        display: flex;
        flex-direction: column;
        gap: spacer(base);
        background: color(contrast, white);

        &__upgradeSelected {
            border: 1px solid color(action, base);
        }
        @include breakpoint(md) {
            width: 100%;
        }
        @include breakpoint(xl) {
            width: 400px;
        }
    }

    &__tag {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: spacer(2xs) spacer(base);
        gap: spacer(3xs);
        position: absolute;
        width: $tagWidth;
        height: $tagHeight;
        left: calc(100% - (#{$tagWidth} + #{spacer(lg)}));
        top: calc((#{$tagHeight} / 2) * -1);
        background: $tagBackgroundColor;
        border-radius: 100px;
        font-weight: 600;
        font-size: set-fontSize(14);
        line-height: 18px;
        text-align: right;
        color: color(contrast, white);
    }

    &__title {
        display: flex;
        width: 100%;
        align-items: center;
    }

    &__checkbox {
        &__input:checked + &__label::before {
            background-color: $checkboxColor !important;
        }

        &__input:focus + &__label::before {
            border: solid 1px $checkboxColor !important;
        }

        &__label {
            padding-left: spacer(2xl);

            &:before {
                box-sizing: border-box;
                position: absolute;
                left: 0;
                top: calc((100% - #{spacer(base)}) / 2) !important;
                width: spacer(base) !important;
                height: spacer(base) !important;
                border: solid 1px $checkboxColor !important;
                background-color: color(contrast, white);
            }

            &:after {
                width: spacer(sm) !important;
                height: 6px !important;
                background: none;
                border-left: spacer(3xs) solid color(contrast, white);
                border-bottom: spacer(3xs) solid color(contrast, white);
                -webkit-transform: scale(0) rotate(-45deg);
                transform: scale(0) rotate(-45deg);
                position: absolute;
                left: calc((#{spacer(base)} - #{spacer(sm)}) / 2) !important;
            }

            &-text {
                width: 100%;
            }
        }
    }

    &__description {
        font-weight: 300;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: spacer(base);
        margin: 0;
        line-height: 18px;

        &__subheader {
            font-weight: 400;
            font-size: spacer(base);
            margin: 0;
        }

        &__features {
            display: flex;
            justify-content: space-between;
            gap: spacer(2dot5xl);
            @include between-breakpoint(md, lg) {
                justify-content: left;
            }

            &__list {
                list-style: outside;
                width: 100%;
            }
        }

        &__picture {
            align-self: center;
        }
    }

    &__label {
        gap: spacer(xs);
        width: 100%;

        &__price {
            font-weight: 300;
            font-size: set-fontSize(16);

            .strikethroughPrice {
                &__discounted,
                &__original {
                    font-weight: 300;
                    color: color(text, base);
                }
            }
        }
        &__text {
            display: inline;
        }
    }
}
