//-------------------------------------------
// Strikethrough Styles
// ------------------------------------------

.strikethroughPrice {
    display: flex;
    color: color(text, base);
    font-weight: 400;
    margin-left: -.33em;

    &--reverseOrder {
        flex-direction: row-reverse;
    }

    &__original {
        color: color(text, recede);
        margin-left: .33em;
        text-decoration: line-through;
    }

    &__discounted {
        margin-left: .33em;
    }

    &__freeLabel {
        color: color(accent);
        font-weight: 400;
        text-transform: uppercase;
    }
}

.clipped {
    position: absolute;
    clip: rect(0 0 0 0);
}
