//-------------------------------------------
// DropdownPanel Styles
// ------------------------------------------

// DropdownPanel Variables

$dropdownPanel-config: (
    text-color: $body-color,
    fontSize: set-fontSize(14),
    fontWeight: 400,
    fontFamily: $fontFamily-sans-serif,
    toggle-bg-color: $white,
    toggle-bg-color-open: color(state, active),
    toggle-border: solid 1px color(contrast, 3),
    list-bg-color: $white,
    list-border: solid 1px color(contrast, 3),
    icon-stroke: color(accent, text),
    icon-stroke-open: color(contrast, white),
    icon-width: 2px,
    icon-transform: rotate(45deg),
    drop-transition: transform 0.25s,
    collapsed-opacity: 1,
    height: $formControl-height,
    safety-height: 40rem,
) !default;

// doing this calc for the default dropdownPanel__list height for scroll
$dropdownPanelListHeight: calc(
    #{map-get($dropdownPanel-config, safety-height) - map-get($dropdownPanel-config, height)}
);

.dropdownPanel__wrapper {
    height: map-get($dropdownPanel-config, height);
    width: 100%;

    @include breakpoint(lg) {
        overflow: hidden;
    }

    &--buystackVariant {
        height: spacer(3xl);

        @include breakpoint(xl) {
            height: spacer(3dot75xl);
        }
    }
}

.dropdownPanel {
    position: absolute;
    top: 0;
    height: map-get($dropdownPanel-config, safety-height);
    width: 100%;
    overflow: hidden;
    z-index: 100;
    pointer-events: none;

    color: map-get($dropdownPanel-config, text-color);
    font-size: map-get($dropdownPanel-config, fontSize);
    font-weight: map-get($dropdownPanel-config, fontWeight);
    font-family: map-get($dropdownPanel-config, fontFamily);

    &__toggle {
        text-align: inherit;
        font: inherit;
        @include btn-reset;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 spacer(sm) 0 spacer(sm);
        width: 100%;
        height: map-get($dropdownPanel-config, height);

        background-color: color(contrast, 1);
        border: map-get($dropdownPanel-config, toggle-border);

        cursor: pointer;
        pointer-events: auto;

        outline: none;

        transition: color 0.3s ease, background-color 0.3s ease;

        &.is-open {
            color: color(contrast, white);
            background-color: map-get($dropdownPanel-config, toggle-bg-color-open);
        }
        &--stateActive {
            background-color: color(state, active);
        }

        &--buystackVariant {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: spacer(3xl);
            border: none;
            border-radius: spacer(3xs);

            @include breakpoint(xl) {
                height: spacer(3dot75xl);
            }

            div, button {
                display: flex;

                .dropdownPanel__icon {
                    stroke: color(text, base);
                    width: 12px;
                    height: 12px;
                    margin-left: 0;
                }
            }

            &.is-open {
                .dropdownPanel {
                    &__text--buystackVariant {
                        font-weight: normal;
                        font-size: set-fontSize(16);
                    }

                    &__icon {
                        stroke: color(contrast, white);
                        stroke-width: 1px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    &__text {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--buystackVariant {
            font-weight: 300;
        }
    }

    &__textSpan--buystackVariant {
        font-weight: normal;
    }

    &__icon {
        margin-left: spacer(xs);
        stroke: map-get($dropdownPanel-config, icon-stroke);
        stroke-width: map-get($dropdownPanel-config, icon-width);
        transition: transform 0.3s ease, stroke 0.3s ease;

        &.is-open {
            stroke: map-get($dropdownPanel-config, icon-stroke-open);
            transform: map-get($dropdownPanel-config, icon-transform);
        }
    }

    &__panel {
        width: 100%;
        top: map-get($dropdownPanel-config, height);
        left: 0px;
        position: absolute;
        z-index: -1;

        background-color: map-get($dropdownPanel-config, list-bg-color);
        border: map-get($dropdownPanel-config, list-border);
        border-top: none;

        opacity: map-get($dropdownPanel-config, collapsed-opacity);
        box-shadow: none;
        // Fix for IE not recognizing the calc() function
        transform: translateY(calc(-100%));
        transition: transform 0.25s, opacity 0.25s;

        display: none;

        pointer-events: auto;

        &--scrolls {
            height: $dropdownPanelListHeight;
            overflow-y: scroll;
        }

        &.is-open {
            display: block;
            opacity: 1;
            transform: translateY(0);
        }

        .dropdown__item {
            padding: spacer(3xs) spacer(sm);
        }

        &--buystackVariant {
            .productPanel__add {
                button {
                    border-radius: spacer(3xs);
                    background-color: color(cta, base);
                    color: color(state, base);
                }
            }
        }
    }

    &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:focus {
            outline: none;
        }
    }

    &__discountText {
        color: color(action, dark);
        font-weight: 700;
        font-style: italic;
    }

    &.is-open {
        .dropdownPanel__discountText {
            color: inherit;
        }
    }

    &--buystackvariant {
        &.is-open {
            z-index: 3000;

            @include breakpoint(lg) {
                width: calc(#{getColumnWidth(4, lg)} + 18px);
                left: -18px;
            }
            @include breakpoint(xl) {
                width: calc(#{getColumnWidth(4, xl)} + 18px);
                left: -18px;
            }
        }
    }
}
