.foundationHeights {
    &__intro {
        margin-bottom: spacer(xl);

        @include breakpoint(md) {
            position: absolute;
            top: 0;
            left: 140px;

            margin-bottom: 0;
        }
    }

    &__header {
        margin-bottom: spacer(sm);

        text-align: left;
    }

    &Diagram {
        @include breakpoint(md) {
            width: 100%;
            padding-top: calc((100% * (304/604)) + 45px);

            background-size: contain;
            background-repeat: no-repeat;
            background-position: left bottom;
        }

        &--zenhaven {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/zenhaven-foundation-height/d-zenhaven-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/zenhaven-foundation-height/d-zenhaven-foundation-height@2x.png');
                }
            }
        }

        &--loom, &--loom-and-leaf {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/loom-and-leaf-foundation-height/d-loom-and-leaf-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/loom-and-leaf-foundation-height/d-loom-and-leaf-foundation-height@2x.png');
                }
            }
        }

        &--contour5, &--saatva-contour5 {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/saatva-contour5-foundation-height/d-saatva-contour5-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/saatva-contour5-foundation-height/d-saatva-contour5-foundation-height@2x.png');
                }
            }
        }

        &--latexHybrid, &--saatva-latex-hybrid {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/latex-hybrid-foundation-height/d-latex-hybrid-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/latex-hybrid-foundation-height/d-latex-hybrid-foundation-height@2x.png');
                }
            }
        }

        &--memoryFoamHybrid, &--memory-foam-hybrid {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/memory-foam-hybrid-foundation-height/d-memory-foam-hybrid-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/memory-foam-hybrid-foundation-height/d-memory-foam-hybrid-foundation-height@2x.png');
                }
            }
        }

        &--saatvaRXMattress, &--saatva-rx {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/saatva-rx-foundation-height/d-saatva-rx-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/saatva-rx-foundation-height/d-saatva-rx-foundation-height@2x.png');
                }
            }
        }

        &--saatvaHDMattress, &--saatva-hd {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/saatva-hd-foundation-height/d-saatva-hd-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/saatva-hd-foundation-height/d-saatva-hd-foundation-height@2x.png');
                }
            }
        }

        &--solaire {
            @include breakpoint(md) {
                background-image: url('#{$rootAssetImagePath}/images/bases/solaire-foundation-height/d-solaire-foundation-height@1x.png');

                @media screen and (min-resolution: 192dpi) {
                    background-image: url('#{$rootAssetImagePath}/images/bases/solaire-foundation-height/d-solaire-foundation-height@2x.png');
                }
            }
        }

        &__content {
            @include breakpoint(md) {
                display: none;
            }
        }

        &__singleImg {
            padding-top: calc((100% - #{$grid-gutter}) * (304/335));

            background-size: contain;
            background-repeat: no-repeat;

            @include breakpoint(md) {
                padding: 0;
            }

            &--zenhaven {
                @include responsive-dpi-img('bases', 'zenhaven-foundation-height', png, 'false');

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--loom, &--loom-and-leaf {
                @include responsive-dpi-img('bases', 'loom-and-leaf-foundation-height', png, 'false');

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--contour5, &--saatva-contour5 {
                @include responsive-dpi-img('bases', 'saatva-contour5-foundation-height', png, 'false');

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--latexHybrid, &--saatva-latex-hybrid {
                @include responsive-dpi-img('bases', 'latex-hybrid-foundation-height', png, 'false', 'true');

                @include breakpoint(md) {
                    background-image: none;
                }
            }

            &--memoryFoamHybrid, &--memory-foam-hybrid {
                @include responsive-dpi-img('bases', 'memory-foam-hybrid-foundation-height', png, 'false', 'true');

                @include breakpoint(md) {
                    background-image: none;
                }
            }
            &--saatvaRXMattress, &--saatva-rx {
                @include responsive-dpi-img('bases', 'saatva-rx-foundation-height', png, 'false', 'true');

                @include breakpoint(md) {
                    background-image: none;
                }
            }
            &--solaire {
                @include responsive-dpi-img('bases', 'solaire-foundation-height', png, 'false', 'true');

                @include breakpoint(md) {
                    background-image: none;
                }
            }
        }
    }
}
