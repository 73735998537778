//-------------------------------------------
// RequestSwatchesModal Styles
// ------------------------------------------

.requestSwatches {
    &__requestSwatchesLinkContainer {
        color: color(darkContrast, 3) !important;
        background-color: transparent !important;
        border: none !important;
        cursor: pointer !important;
        font-size: set-fontSize(16) !important;
        justify-content: flex-start !important;
        min-width: 0 !important;
        padding: 0 !important;
        text-decoration-line: underline !important;
        width: 0 !important;

        &:hover {
            background-color: transparent !important;
            color: color(nav, active) !important;
        }

        &:focus {
            background-color: transparent !important;
            border: none !important;
            color: color(darkContrast, 3) !important;

            &:hover {
                background-color: transparent !important;
                color: color(nav, active) !important;
            }
        }
    }
}

.requestSwatchesModal {
    &__swatchesContainer {
        &__images{
            pointer-events: none;
            margin-bottom: spacer(xs);
            width: 150px;
            max-width: 100%;
            min-height: 150px;
        }

        // styles for delayed message that appears at the top of a swatch
        &__delayed {
            position: absolute;
            top: 0;
            color: white;
            z-index: 1;
            margin-left: 0px;
            letter-spacing: 1.5px;
            background-color: #463F38;
            padding: 0px spacer(xs);
            font-weight: bold;
            font-size: set-fontSize(11);
        }
    }

    &__backorder {
        position: absolute;
        // Not using color color(text, heading) because this isn't a heading, it's more like a disclaimer
        color: #6b6257;
        font-size: set-fontSize(14);
        font-weight: normal;
        margin-top: spacer(2xs);
    }

    &__submitBtnContainer {
        &--hide {
            display: none;
        }

        width: 100%;
        height: spacer(5xl);
        margin-top: spacer(2xl);
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid color(contrast, 3);
        
        @include breakpoint(md) {
            border-top: none;
            height: auto;
        }
    }

    &__submitBtn {
        width: 100%;
        height: 40px;
        display: flex;
        font-size: set-fontSize(17);

        @include breakpoint(md) {
            width: auto;
            margin: spacer(2dot5xl) auto;
        }
    }

    &__textInput {
        margin: spacer(2xl) 0 spacer(xs);
        border-bottom-color: color(nav, base);

        &--name{
            margin: spacer(2dot5xl) 0 spacer(xs);
        }
    }

    &__textInputLabel {
        font-weight: 400;
        color: color(text, hover);
    }

    &__dropdown {
        position: relative;
        top: 1px;

        margin: spacer(2xl) 0 spacer(xs);

        .dropdown__toggle {
            padding: 0;
            border: none;
            border-bottom: 1px solid color(nav, base);
        }
    }

    &__contentContainer {
        &--hide {
            height: 0;
            margin: 0;
            padding: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility 1s linear, opacity 1s linear;
        }

        transition: visibility 1s linear, opacity 1s linear;
        padding-top: spacer(2xl);
        overflow-y: visible;

        @include breakpoint(md) {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    &__formSuccessContainer, &__formErrorContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 50%;
        margin-bottom: 72%;
        overflow: hidden;
        transition: visibility 1s linear, opacity 1s linear;

        @include breakpoint(md) {
            margin-top: 37%;
            margin-bottom: 46%;
        }

        &--hide {
            height: 0;
            margin: 0;
            padding: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility 1s linear, opacity 1s linear;
        }
    }
}

.requestModal_CloseBtn {
    width: 15px;
    height: 15px;
}

.requestButton_link {
    background-color: transparent;
    border: none;
    color: color(text, headingDark);
    font-size: 1rem;
}