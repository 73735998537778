.foundationBaseAddon {
    &__dropdownPanelWrapper {
        @include breakpoint(lg) {
            position: static;
        }
    }

    &__dropdown {
        z-index: 1;
        overflow: visible;
        position: relative;
        height: auto;

        &.is-open,
        &.is-closing {
            z-index: 100;
            animation: revealRelatedProductsDropdownPanel 0.3s linear;
            position: absolute;
            top: -150px;

            @include breakpoint(lg) {
                height: auto;
                top: 150px;
                padding: 0 spacer(sm);
            }
        }

        &.is-closing {
            animation: revealRelatedProductsDropdownPanel--reverse 0.3s linear reverse;
        }

        &__text {
            &__added {
                color: #c1a38b;
            }
        }

        .dropdownPanel__toggle {
            font-size: set-fontSize(13);

            @include breakpoint(md) {
                font-size: set-fontSize(14);
            }
        }
    }

    &__tabList {
        padding-top: spacer(base);
        border-bottom: 1px solid color(contrast, 3);
        justify-content: space-around;

        &__addonTile {
            width: 100%;
            height: 100%;

            &:first-child {
                margin-right: 0;
            }

            &:nth-child(2) {
                margin-left: 0;
            }
        }

        &__slider {
            width: 50%;

            &Left {
                left: 0;
            }

            &Right {
                left: 50%;
            }

            &Only {
                width: 100%;
            }
        }
    }

    &__tab {
        &:first-child {
            margin-right: spacer(md);
        }

        &:nth-child(2) {
            margin-left: spacer(md);
        }

        &--onlyTab {
            & > div.standardTab {
                color: color(accent, text);
            }
        }
    }

    &__dropdownPanel {
        @include breakpoint(lg) {
            opacity: 0;
            top: 0;
            position: relative;
            transform: translateY(0);
            pointer-events: none;
            transition: none;
            &.is-open,
            &.is-closing {
                pointer-events: all;
                opacity: 1;
            }
        }

    }

    &__dropdownPanelOption {
        padding: spacer(base) !important;

        &.is-disabled {
            opacity: 0.5;
        }
        & + hr {
            border: none;
            border-top: 1px solid color(contrast, 3);
        }
    }

    &__panelContentContainer {
        height: 432px;
        overflow: hidden;

        @include breakpoint(520px) {
            height: 450px;
        }
        @include breakpoint(xl) {
            height: 448px;
        }

        &__specificMobileHeight {
            height: 460px;

            @include breakpoint(520px) {
                height: 375px;
            }
            @include breakpoint(xl) {
                height: 448px;
            }
        }

        &__foundationTab {
            &--noFootnote {
                height: 265px;
            }
            &--frameHidden {
                height: 265px;

                &--noFootnote {
                    height: 160px
                }
            }
        }

        &__baseTab {
            background: color(contrast, 1);
            height: 638px;
            @include breakpoint(md) {
                height: 554px;
            }
            @include breakpoint(lg) {
                height: 622px;
            }
            @include breakpoint(xl) {
                height: 571px;
            }
            @include breakpoint(2xl) {
                height: 599px;
            }
            &--error {
                height: 695px;
                @include breakpoint(md) {
                    height: 595px;
                }
                @include breakpoint(lg) {
                    height: 663px;
                }
                @include breakpoint(xl) {
                    height: 612px;
                }
                @include breakpoint(2xl) {
                    height: 628px;
                }
            }
            &--oneTile {
                height: 374px;
                @include breakpoint(md) {
                    height: 320px;
                }
                @include breakpoint(lg) {
                    height: 340px;
                }
                @include breakpoint(xl) {
                    height: 304px;
                }
                @include breakpoint(2xl) {
                    height: 338px;
                }
                &--error {
                    height: 425px;
                    @include breakpoint(md) {
                        height: 369px;
                    }
                    @include breakpoint(lg) {
                        height: 393px;
                    }
                    @include breakpoint(xl) {
                        height: 366px;
                    }
                    @include breakpoint(2xl) {
                        height: 382px;
                    }
                }
            }
        }
    }

    &__panelContent {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(0);
        width: 100%;
        transition: transform 500ms ease;

        &__baseTab {
            display: flex;
            gap: spacer(xl);
            flex-direction: column;
            align-items: center;
            padding: spacer(lg);

            @include between-breakpoint(lg, xl) {
                padding: spacer(xs);
            }
        }

        &:not(.active) {
            transform: translateX(-100%);
        }
    }

    &__productDescription {
        margin: spacer(2xs) 0 spacer(sm);

        font-weight: $fontWeight-light;

        &__extraNote {
            line-height: 20px;
        }

        &Header {
            display: block;
            margin-top: spacer(2xs);

            font-weight: $fontWeight-base;
        }
    }

    &__chooseHeight {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &Text {
            flex-basis: 40%;

            line-height: 1.25;

            @include breakpoint(sm) {
                flex-basis: 35%;
            }
        }

        &Buttons {
            flex-basis: 60%;

            @include breakpoint(sm) {
                flex-basis: 65%;
            }
        }
    }

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: set-fontSize(14);

        @include breakpoint(sm) {
            font-size: set-fontSize(16);
        }

        &Text {
            display: flex;
            line-height: 1;
            white-space: nowrap;
            width: 100%;

            @include breakpoint(sm) {
                font-size: set-fontSize(14);
            }

            @include breakpoint(xl) {
                font-size: set-fontSize(16);
            }

            &--wraps {
                width: 180px;
                display: block;
                line-height: 1.2;
            }
        }

        &Price {
            font-weight: $fontWeight-light;
        }

        &Image {
            display: flex;
            flex-direction: column;
            width: 75px;
            img {
                width: 75px;
            }
        }
    }

    &__list {
        padding-left: spacer(base);
        list-style-type: disc;
    }

    &__bottomText {
        padding: spacer(xs) spacer(base) spacer(base);
        font-weight: $fontWeight-light;
    }

    &__checkbox {
        // TODO: This is a placement fix for a potential miscalculation in the pattern library
        .formCheckbox__label::after {
            top: auto;
            margin-top: -2px;
        }
    
        // Overrides default component styles to match Adjustable Bases section
        &__label {
            padding-left: spacer(2xl);

            &:before {
                box-sizing: border-box;
                position: absolute;
                left: 0;
                top: calc((100% - #{spacer(base)}) / 2) !important;
                width: spacer(base) !important;
                height: spacer(base) !important;
                border: solid 1px color(action, base) !important;
                background-color: color(contrast, white);
            }

            &:after {
                width: spacer(sm) !important;
                height: 6px !important;
                background: none;
                border-left: spacer(3xs) solid color(contrast, white);
                border-bottom: spacer(3xs) solid color(contrast, white);
                -webkit-transform: scale(0) rotate(-45deg);
                transform: scale(0) rotate(-45deg);
                position: absolute;
                left: calc((#{spacer(base)} - #{spacer(sm)}) / 2) !important;
            }

            &-text {
                width: 100%;
            }
        }

        // Fixes the checkbox background color
        &__input:checked + &__label::before {
            background-color: color(action, base) !important;
        }
    }

    &__adjustableBasePlusFeatures {
        list-style: inside;
    }

    &__discountText {
        font-weight: 700;
        font-style: italic;

        @include breakpoint(md) {
            font-size: set-fontSize(13);
        }

        @include breakpoint(xl) {
            font-size: set-fontSize(14);
        }
    }

    &__adjustableBasePlusError {
        color: color(negative, base);
        font-weight: 300;
        line-height: 1.25rem;
        margin-left: 0;
        margin-top: #{spacer(base) * -1};
        padding: 0 spacer(base);
    }

    &__buystackErrorMessage {
        color: color(negative, base);
        line-height: 18px;
        font-size: set-fontSize(11);
        margin-bottom: spacer(xs);

        @include breakpoint(md) {
            font-size: set-fontSize(14);
        }
    }

    &__learnMoreToggle {
        margin: auto 0 spacer(xs) auto;
    }


    @keyframes growDown {
        0% {
            transform: scaleY(0);
        }
        100% {
            transform: scaleY(1);
        }
    }

    @keyframes revealRelatedProductsDropdownPanel {
        0% {
            opacity: 0;
            clip-path: inset(50% 0);
        }
        25% {
            opacity: 1;
        }
        50% {
            clip-path: inset(25% 0);
        }
        100% {
            clip-path: inset(0 0);
        }
    }

    @keyframes revealRelatedProductsDropdownPanel--reverse {
        0% {
            opacity: 0;
            clip-path: inset(50% 0);
        }
        25% {
            opacity: 1;
        }
        50% {
            clip-path: inset(25% 0);
        }
        100% {
            clip-path: inset(0 0);
        }
    }
}

