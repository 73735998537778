.dropdownPanel {
    &--dropdownBuystackAddon {
        position: relative;

        @include breakpoint(lg) {
            height: auto;
            &.is-open,
            &.is-closing {
                animation: revealRelatedProductsDropdownPanel 0.3s linear;
                top: 0;
                z-index: 102; // 102 because we have no z() for between the dropdowns in the PDP
                padding-right: spacer(xl);

                @include breakpoint(lg) {
                    height: auto;
                    // vertically centers the panel, 20px is half the the height of the dropdown
                    transform: translateY(calc(-50% + 20px));
                }
            }
            &.is-closing {
                animation: revealRelatedProductsDropdownPanel--reverse 0.3s linear reverse;
            }
        }
    }

    &.is-open {
        @include breakpoint(lg) {
            padding: 0 spacer(sm);
        }
    }

    &__wrapper {
        &--dropdownBuystackAddon {
            overflow: visible;
            @include breakpoint(lg) {
                position: static;
            }
        }
    }

    &__panel {
        &--dropdownBuystackAddon {
            @include breakpoint(lg) {
                opacity: 0;
                top: 0;
                position: relative;
                transform: translateY(0);
                pointer-events: none;
                transition: none;
                &.is-open,
                &.is-closing {
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }
    }
}

.dropdownBuystackAddon {
    &__confirmButton {
        width: 100% !important;
        min-width: unset !important;
    }

    &__add {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        justify-content: space-between;
        line-height: 40px;

        &--inline {
            flex-wrap: nowrap;
            align-items: center;
        }
    }

    &__price {
        font-size: set-fontSize(20);
        line-height: 1;
        text-align: center;
    }

    &__onSale {
        color: color(negative, base);
    }
}