/*------------------------------------*\
    Pattern Library Override Variables
\*------------------------------------*/


// sizing

/*-- TODO: The following overrides should be moved to the pattern library saatva theme --*/
$typographyTheme-custom: (
    'headingHero': (
        'size':               28,
        'size-tablet':        36,
        'size-desktop':       40,
        'lineHeight':         1.29,
        'lineHeight-tablet':  1.33,
        'lineHeight-desktop': 1.2,
        'weight':             $fontWeight-light,
        'transform':          none,
        'marginBottom':       spacer(0),
        'color':              color(accent, text),
        'fontFamily':         $fontFamily-serif
    ),
    'heading1': (
        'size':               28,
        'size-tablet':        36,
        'size-desktop':       40,
        'lineHeight':         1.29,
        'lineHeight-tablet':  1.33,
        'lineHeight-desktop': 1.2,
        'weight':             $fontWeight-light,
        'transform':          none,
        'marginBottom':       spacer(0),
        'color':              color(text, heading),
        'fontFamily':         $fontFamily-serif
    ),
    'heading2': (
        'size':               24,
        'size-tablet':        28,
        'size-desktop':       32,
        'lineHeight':         1.5,
        'lineHeight-tablet':  1.43,
        'lineHeight-desktop': 1.5,
        'weight':             $fontWeight-light,
        'transform':          none,
        'marginBottom':       spacer(md),
        'color':              color(text, heading),
        'fontFamily':         $fontFamily-serif
    ),
    'heading3': (
        'size':         20,
        'lineHeight':   1.4,
        'weight':       $fontWeight-base,
        'transform':    normal,
        'marginBottom': spacer(xs),
        'color':        color(text, heading)
    ),
    'heading3--serif': (
        'size':         24,
        'lineHeight':   1.6,
        'weight':       $fontWeight-light,
        'transform':    normal,
        'marginBottom': spacer(sm),
        'fontFamily':   $fontFamily-serif,
        'color':        color(text, heading)
    ),
    'heading4': (
        'size':               18,
        'lineHeight':         1.56,
        'weight':             $fontWeight-base,
        'transform':          none,
        'marginBottom':       spacer(base),
        'color':              color(text)
    ),
    'heading5': (
        'size':         16,
        'lineHeight':   1.75,
        'weight':       $fontWeight-base,
        'transform':    none,
        'marginBottom': spacer(0)
    ),
    'heading6': (
        'size':         16,
        'lineHeight':   1.43,
        'weight':       $fontWeight-base,
        'transform':    uppercase,
        'letterSpacing': 0.5px,
        'marginBottom': spacer(0)
    ),
    'body': (
        'size':                 16,
        'lineHeight':           1.75,
        'weight':               $fontWeight-light,
        'transform':            none,
    ),
    'bodyMd': (
        'size':                 16,
        'lineHeight':           1.75,
        'weight':               $fontWeight-light,
        'transform':            none,
        'marginBottom':         spacer(xl)
    ),
    'bodyLg': (
        'size':                 16,
        'size-tablet':          18,
        'lineHeight':           1.75,
        'lineHeight-desktop':   1.78,
        'weight':               $fontWeight-light,
        'transform':            none,
        'marginBottom':         spacer(2xl)
    ),
    'bodySm': (
        'size':         14,
        'lineHeight':   1.71,
        'weight':       $fontWeight-light,
        'transform':    none,
        'marginBottom': spacer(sm)
    ),
    'disclaimer': (
        'size':         10,
        'lineHeight':   1.6,
        'weight':       $fontWeight-base,
        'transform':    none,
        'marginBottom': none,
        'color':        color(text, recede)
    ),
    'footer': (
        'size':         12,
        'lineHeight':   1.5,
        'weight':       $fontWeight-base,
        'transform':    normal,
        'marginBottom': spacer(0)
    )
);

/*-- TODO: END pattern library theme overrides --*/

// font-family settings
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdq.eot?#iefix') format('eot'), /* IE6-IE8 */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu.woff2') format('woff2'), /* Super Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdo.woff') format('woff'), /* Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdr3cOWxw.ttf') format('truetype'), /* Safari, Android, iOS */
    url('//fonts.gstatic.com/l/font?kit=6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdp&skey=e2b26fc7e9e1ade8&v=v11#SourceSansPro') format('svg');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7h.eot?#iefix') format('eot'), /* IE6-IE8 */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2') format('woff2'), /* Super Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7j.woff') format('woff'), /* Modern Browsers */
    url('//fonts.gstatic.com/s/sourcesanspro/v11/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7gujVj9w.ttf') format('truetype'), /* Safari, Android, iOS */
    url('//fonts.gstatic.com/l/font?kit=6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7i&skey=1e026b1c27170b9b&v=v11#SourceSansPro') format('svg');
}