// //-------------------------------------------
// // Flickity Styles
// // ------------------------------------------

@use "sass:math";

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-enabled:focus {
  outline: none;
}

/* no circle */
.flickity-button {
    background: transparent;
}
/* big previous & next buttons */
.flickity-prev-next-button {
    display: none;
    width: spacer(base);
    height: spacer(base);
    position: absolute;
    transform: translate(-45%);
    top: 45%;
    cursor: pointer;

    @include breakpoint(lg){
        display: block;
    }

    &.previous{
        left: -25px;
    }
    &.next{
        right: -40px;
    }
}

/* icon color */
.flickity-button-icon {
  fill: #585858;
}

.flickity-page-dots {
  width: 100%;
  margin: spacer(2xl) 0 0;
  line-height: 1;
}

/* white circles */
// TODO: Update the color of these to color(nav) as that seems to be the pattern from design
// We will need to go through all Flickity instances to confirm when implementing that update
.flickity-page-dots .dot {
  width: spacer(xs);
  height: spacer(xs);
  opacity: 1;
  background: transparent;
  border: 1px solid #585858;
  display: inline-block;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  @include breakpoint(md) {
    width: spacer(sm);
    height: spacer(sm);
  }
}

.slides--refined .flickity-page-dots {
  display: flex;
  .dot {
    background: color(contrast, 3);
    border: color(contrast, 3);
    border-radius: 0;
    margin: 0;
    height: 1.5px;
    width: 100%;
  
    @include breakpoint(lg) {
        height: 1px;
    }
  }
}

/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: #585858;
}

// custom container class to account for widths up to 1024px in tablet
.flickity__container {
  // maintain container-to-width ratio
  @include breakpoint(md) {
    max-width: calc(100% * #{math.div(map-get($container-widths, md), map-get($breakpoints, md))}); 
  }

  // accounts for double gutter from nested columns
  @include breakpoint(lg) {
    max-width: calc(#{map-get($container-widths, lg)} + (2 * #{$grid-gutter-desktop}));
  }

  @include breakpoint(xl) {
    max-width: calc(#{map-get($container-widths, xl)} + (2 * #{$grid-gutter-desktop}));
  }
}