.addOnToggle {
    padding: spacer(sm) spacer(xs) !important;
    justify-content: center;
    align-items: center;
    font-size: set-fontSize(14);
    min-width: 100px;
    width: 100%;

    &:focus {
        color: color(darkContrast, 2);
        background-color: color(contrast, 2);
    }

    &:hover, &:active, &.is-active {
        color: color(contrast, white);
        background-color: color(darkContrast, 2);

        .addOnToggle__plusIcon {
            stroke: color(darkContrast, 2) !important;
            background-color: color(contrast, white);
        }
    }

    @media (hover: none) {
        &:hover {
            color: inherit;
            background-color: inherit;

            .addOnToggle__plusIcon {
                stroke: color(contrast, white);
                background-color: color(darkContrast, 2);
            }
        }
        &.is-active {
            color: color(contrast, white);
            background-color: color(darkContrast, 2);
        }
    }

    &__plusIcon {
        stroke: color(contrast, white) !important;
        background-color: color(darkContrast, 2);
        width: 14px;
        height: 14px;
        padding: spacer(2xs);
        border-radius: 50%;
        margin-right: spacer(2xs);
        transition: all .2s ease;
    }

    &__checkIcon {
        top: auto; // TODO: remove hardcoded positioning on icon--check
        left: auto; // TODO: remove hardcoded positioning on icon--check
        stroke: color(darkContrast, 2);
        stroke-width: 3px;
        background-color: color(contrast, white);
        width: 14px;
        height: 14px;
        padding: spacer(3xs);
        border-radius: 50%;
        margin-right: spacer(2xs);
        transition: all .2s ease;
    }
}
